// @flow
// (Copyright) Confluent, Inc.

import * as React from 'react';
import styled from 'styled-components';
import { ButtonSecondaryMedium } from '@confluent/core';

import PluginModal from '../../PluginModal';

type Props = { results: number, ... };

const Header = ({ results }: Props): React.Node => {
  const [showSubmitPluginModal, setShowSubmitPluginModal] = React.useState(false);
  return (
    <Wrapper>
      <Results>
        <strong>Results</strong> ({results})
      </Results>
      <ButtonWrapper>
        <ButtonSecondaryMedium
          icon="add"
          onClick={() => setShowSubmitPluginModal(true)}
          text="Submit a plugin"
        />
      </ButtonWrapper>
      {showSubmitPluginModal && (
        <PluginModal
          header="Submit plugin"
          onClose={() => setShowSubmitPluginModal(false)}
          type="Submit"
        />
      )}
    </Wrapper>
  );
};

export default Header;

const Results = styled.div`
  font-size: 24px;
  line-height: 32px;
  color: var(--DIM_DARKER);
  font-weight: 300;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
`;

const ButtonWrapper = styled.div``;
