// (Copyright) Confluent, Inc.
// @flow

import * as React from 'react';
import styled from 'styled-components';

import PluginModal from '../../PluginModal';

const EmptyState = (): React.Node => {
  const [showRequestPluginModal, setShowRequestPluginModal] = React.useState(false);
  return (
    <Overwrapper>
      <Wrapper>
        <Title>No matching results</Title>
        <Body>
          We can&apos;t seem to find any results that match your search. However you may request a
          plugin you think we could add to the Confluent Hub.
        </Body>
        <Footer onClick={() => setShowRequestPluginModal(true)}>Request a new plugin</Footer>
      </Wrapper>
      {showRequestPluginModal && (
        <PluginModal
          header="Request plugin"
          onClose={() => setShowRequestPluginModal(false)}
          type="Request"
        />
      )}
    </Overwrapper>
  );
};

export default EmptyState;

const Overwrapper = styled.div`
  display: flex;
  height: 35vh;
  justify-content: center;
  flex-direction: column;
`;

const Wrapper = styled.div`
  margin: 0 auto;
  text-align: center;
  color: var(--DIM);
  max-width: 440px;
`;

const Title = styled.div`
  font-size: 18px;
  line-height: 24px;
`;

const Body = styled.div`
  font-size: 14px;
  line-height: 22px;
  font-weight: 300;
  margin: 12px 0;
`;

const Footer = styled.button`
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
  background: none;
  border: none;
`;
