// (Copyright) Confluent, Inc.
// @flow

import React from 'react';

const useClient = () => {
  const [isClient, setClient] = React.useState(false);

  React.useEffect(() => {
    setClient(true);
  }, []);

  return { isClient, key: isClient ? 'client' : 'server' };
};

export default useClient;
