// (Copyright) Confluent, Inc.
// @flow

import * as React from 'react';
import styled from 'styled-components';

type Props = {
  onChange: (values: Array<string>) => void,
  options: Array<{
    label: React.Node,
    value: $FlowTODO,
    ...
  }>,
  values: Array<string>,
  ...
};

const CheckboxGroup = ({ values, options, onChange }: Props) => (
  <Wrapper>
    {options.map(({ label, value }, index) => (
      <Value key={index}>
        <input
          checked={values.includes(value)}
          onChange={() => onChange(getNextValues(values, value))}
          type="checkbox"
        />
        {label}
      </Value>
    ))}
  </Wrapper>
);

export default CheckboxGroup;

const Wrapper = styled.div`
  margin: 0;
`;

const Value = styled.label`
  display: block;
  cursor: pointer;
  list-style: none;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }

  input[type='checkbox'] {
    vertical-align: middle;
    margin: -6px 8px 0 0;
  }
`;

const getNextValues = (values, value) =>
  values.indexOf(value) !== -1 ? removeArrayElement(values, value) : [...values, value];

const removeArrayElement = (list, element) => [
  ...list.slice(0, list.indexOf(element)),
  ...list.slice(list.indexOf(element) + 1),
];
