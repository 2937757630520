// (Copyright) Confluent, Inc.
// @flow
import React from 'react';
import { A, ButtonPrimaryLarge, Modal, ModalBody, ModalFooter } from '@confluent/core';

type Props = {
  header: string,
  onClose: () => void,
  type: 'Submit' | 'Request',
};

const email = 'confluent-hub@confluent.io';

const PluginModal = ({ header, onClose, type }: Props) => (
  <Modal header={header} onClose={onClose}>
    <ModalBody>
      Send us an email at <A href={`mailto:${email}?subject=${type} plugin`}>{email}</A>{' '}
      {type === 'Submit' ? 'to submit your plugin.' : 'and we will take care of your request.'}
    </ModalBody>
    <ModalFooter>
      <ButtonPrimaryLarge onClick={onClose} text="Okay" type="button" />
    </ModalFooter>
  </Modal>
);

export default PluginModal;
