// @flow
// (Copyright) Confluent, Inc.

import * as React from 'react';
import styled from 'styled-components';

type Props = { children: React.Node, ... };

const Search = ({ children }: Props) => (
  <Background>
    <Wrapper>
      <LogoWrapper>
        <Line />
        <Logo>Confluent Hub</Logo>
        <Line />
      </LogoWrapper>
      <Title>
        Discover Kafka<span>®</span> connectors and more
      </Title>
      {children}
    </Wrapper>
  </Background>
);

export default Search;

const Background = styled.div`
  margin: 0 24px 24px;
  background-color: var(--WHITE);
`;

const Wrapper = styled.div`
  background-color: var(--DIM_DARK);
  border-radius: 8px;
  padding: 73px 0 80px 0;

  @media (max-width: 560px) {
    padding: 40px 0;
  }
`;

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 12px auto;
`;

const Logo = styled.h1`
  color: var(--PRIMARY_LIGHT);
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  margin: 0;

  @media (max-width: 560px) {
    font-size: 12px;
  }
`;

const Line = styled.div`
  width: 80px;
  height: 1px;
  background: var(--WHITE);
  margin: 0 32px;

  @media (max-width: 560px) {
    width: 56px;
  }
`;

const Title = styled.h2`
  font-size: 58px;
  line-height: 68px;
  text-align: center;
  color: var(--WHITE);
  font-weight: 200;
  margin: 0 auto 26px auto;
  max-width: 760px;

  span {
    font-size: 16px;
  }

  @media (max-width: 800px) {
    max-width: 640px;
  }

  @media (max-width: 560px) {
    font-size: 28px;
    line-height: 32px;
    max-width: 330px;
    span {
      font-size: 12px;
    }
  }
`;
