// @flow
// (Copyright) Confluent, Inc.

import React from 'react';
import styled from 'styled-components';
import { navigate, Link } from 'gatsby';
import { analytics } from '@confluent/core';
import { NewsletterSignupCard } from '@confluent/connector-plugin-view';

import Layout from '../../components/Layout';
import Seo from '../../components/Seo';
import usePlugins from '../../hooks/usePlugins';
import useMedia from '../../hooks/useMedia';
import useClient from '../../hooks/useClient';
import { filterPlugins, INITIAL_FILTERS } from '../../models/plugin';
import { PLUGINS_PER_PAGE } from '../../constants/config';

import Pagination from './Pagination';
import List from './List';
import { SearchWrapper, SearchInput } from './Search';
import Filters from './Filters';
import { parseQuery, serializeQuery } from './util';

type Props = {
  initialPage: number,
  location: { hash: string, ... },
  ...
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'QUERY':
      // query resets filters
      return {
        ...state,
        query: action.query,
        filters: INITIAL_FILTERS,
        page: 1,
      };
    case 'FILTERS':
      return { ...state, filters: action.filters, page: 1 };
    case 'PAGE':
      return { ...state, page: action.page };
    default:
      throw new Error(`Unhandled reducer case ${action.type}`);
  }
};

const IndexPage = ({ location, initialPage = 1 }: Props) => {
  const { isClient, key: clientKey } = useClient();
  const [state, dispatch] = React.useReducer(reducer, {
    filters: INITIAL_FILTERS,
    query: '',
    page: initialPage,
  });

  const isSmall = useMedia('(max-width: 1000px)');
  const config = isSmall ? { edge: 0, around: 0 } : { edge: 2, around: 1 };

  const { query, filters, page } = state;

  const plugins = usePlugins();

  const filteredPlugins = filterPlugins(plugins, query, filters);
  const pagePlugins = filteredPlugins.slice(PLUGINS_PER_PAGE * (page - 1), PLUGINS_PER_PAGE * page);
  const totalPlugins = Math.ceil(filteredPlugins.length / PLUGINS_PER_PAGE);

  const setPage = (page) => {
    analytics.track({
      name: 'Hub Search Page Changed',
      properties: {
        query,
        results: filteredPlugins.length,
        filters,
      },
    });
    dispatch({ type: 'PAGE', page });
    window.scrollTo(0, 0);
  };
  const setFilters = (filters) => {
    dispatch({ type: 'FILTERS', filters });
  };

  const onSearch = (query) => {
    navigate(`/${serializeQuery(query)}`);
  };

  React.useEffect(() => {
    dispatch({ type: 'PAGE', page: initialPage });
  }, [initialPage]);

  React.useEffect(() => {
    const query = parseQuery(location.hash);
    if (query !== state.query) {
      dispatch({ type: 'QUERY', query });
    }
  }, [location.hash, state.query, isClient]);

  React.useEffect(() => {
    if (!isInitialState(state)) {
      analytics.track({
        name: 'Hub Search List Filtered',
        properties: {
          query: state.query,
          page: state.page,
          results: filteredPlugins.length,
          filters,
        },
      });
    }
  }, [JSON.stringify(state)]); // eslint-disable-line

  // isInitialState(state) == true on initial render when we would like to show static links with href's
  const renderPage = isInitialState(state) ? renderStaticPage : undefined;

  return (
    <Layout>
      <Seo includeSwiftype={true} title="Home" />
      <SearchWrapper>
        <SearchInput initialInputValue={query} key={clientKey} onSearch={onSearch} />
      </SearchWrapper>
      <Wrapper>
        <Left>
          <Filters onChange={setFilters} value={filters} />
          <NewsletterSignupCard />
        </Left>
        <Right>
          <List key={clientKey} plugins={pagePlugins} results={filteredPlugins.length} />
          {totalPlugins > 1 && (
            <PaginationWrapper>
              <Pagination
                config={config}
                current={page}
                onPageChange={setPage}
                renderPage={renderPage}
                showSiblingPages={true}
                total={totalPlugins}
              />
            </PaginationWrapper>
          )}
        </Right>
      </Wrapper>
    </Layout>
  );
};

export default IndexPage;

const isInitialState = (state) => state.filters === INITIAL_FILTERS && state.query === '';

const renderStaticPage = ({ page, text }) => (
  <PageLink to={`/kafka-connectors-${page}`}>{text}</PageLink>
);

const PageLink = styled(Link)`
  display: block;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  background: var(--DIM_LIGHTEST);
  padding: 50px 32px;
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;

  @media (max-width: 680px) {
    margin: 0;
  }

  @media (max-width: 420px) {
    padding: 50px 0;
  }

  ::after {
    content: '';
    display: table;
    clear: both;
  }
`;

const Left = styled.div`
  width: 320px;
  min-width: 320px;
  max-width: 320px;
  margin-right: 78px;

  @media (max-width: 1280px) {
    margin-right: 32px;
  }

  @media (max-width: 800px) {
    display: none;
  }
`;

const Right = styled.div`
  flex-grow: 1;
`;

const PaginationWrapper = styled.div`
  text-align: center;
  margin: 76px auto 56px auto;
`;
