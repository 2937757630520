// (Copyright) Confluent, Inc.
// @flow

export const parseQuery = (hash: string): string => {
  const hashPos = hash.indexOf('#');
  if (hashPos !== -1) {
    return decodeURIComponent(hash.substring(hashPos + 1));
  }

  return '';
};

export const serializeQuery = (query: string): string => `#${encodeURIComponent(query)}`;
